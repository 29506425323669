<template>
  <div
    class=""
    :class="!isModal ? 'px-2 py-1' : ''"
    v-shortkey="{
      macSave: ['meta', 's'],
      macMacro1: ['meta', '1'],
      macMacro2: ['meta', '2'],
      macMacro3: ['meta', '3'],
      macMacro4: ['meta', '4'],
      macMacro5: ['meta', '5'],
      macMacro6: ['meta', '6'],
      macMacro7: ['meta', '7'],
      winSave: ['ctrl', 's'],
      winMacro1: ['alt', '1'],
      winMacro2: ['alt', '2'],
      winMacro3: ['alt', '3'],
      winMacro4: ['alt', '4'],
      winMacro5: ['alt', '5'],
      winMacro6: ['alt', '6'],
      winMacro7: ['alt', '7'],
    }"
    @shortkey.stop="shotKeyEvent"
  >
    <!-- 버튼 툴바 -->
    <div
      v-if="false"
      class="flex border-b py-1 px-1.5 justify-end space-x-1.5"
      :class="!isModal ? 'pb-2' : ''"
    >
      <!-- 출고상태 -->
      <div class="pr-2">
        <ArticleStatusUI
          :approve-code="approveTypeCd"
          :is-cuesheet-matching="isMatchingCuesheet"
        />
      </div>
      <!-- 저장 -->
      <GSaveButton
        v-tooltip="'기사 저장'"
        icon="arrow-down-tray"
        :disabled="isLoading"
        :confirm-data="confirmData"
        :loading="isSaveLoading"
        text="저장"
        @click="onSaveArticle"
      />
      <!-- 송고 -->
      <GConfirmButton
        v-if="currentRouteName === 'myArticle'"
        v-tooltip="'송고'"
        icon="document-arrow-up"
        :disabled="isLoading || isSaveLoading"
        :title="title"
        :html="setConfirmHtml('transfer')"
        text="송고"
        @on-confirm="isTransfer = true"
      />
      <!-- 출고 -->
      <GConfirmButton
        v-if="currentRouteName === 'coverDesk'"
        v-tooltip="'출고'"
        icon="chul-go"
        :disabled="isLoading || isSaveLoading"
        :title="title"
        :html="setConfirmHtml('approve')"
        text="출고"
        :show-confirm-button="setConfirmButton"
        confirm-button-text="출고(기사)"
        :show-deny-button="true"
        deny-button-text="출고(기사+자막)"
        deny-button-color="#22C55E"
        confirm-button-color="#dc3741"
        @on-confirm="okConfirmHandler"
        @on-deny="deniedConfirmHandler"
      />
      <!-- 맞춤법 -->
      <GButton
        v-tooltip="'맞춤법'"
        icon="spellcheck"
        :disabled="isLoading"
        @click="isShowSpellCheckModal = true"
      />
      <!-- 인쇄 -->
      <GButton
        v-tooltip="'인쇄'"
        icon="printer"
        :disabled="isLoading"
        @click="isShowPrintOptionModal = true"
      />

      <!-- 홈페이지 전송  -->
      <GConfirmButton
        v-if="!isShowSendHomePageButton"
        v-tooltip="t('MyRegionArticle.홈페이지_전송')"
        icon="cloud-arrow-up"
        :disabled="isDisabledSendHomePageButton"
        :title="title"
        :html="setConfirmHtml('homepage')"
        @on-confirm="onClickedSendHomepage"
      />

      <!-- 홈페이지 전송 취소 -->
      <GConfirmButton
        v-if="isShowSendHomePageButton"
        v-tooltip="t('MyRegionArticle.홈페이지_전송_취소')"
        icon="cloud"
        :title="title"
        :html="setConfirmHtml('cancelHomepage')"
        @on-confirm="onUnSendHomePage"
      />
    </div>

    <!-- 내용 -->
    <div
      class="h-[calc(100vh-146px)] border-gray-200 bg-slate-100 rounded-b-lg"
    >
      <div class="flex justify-center relative">
        <div>
          <div>
            <!-- 버튼 툴바 -->
            <div
              class="flex border-b py-1 px-1.5 justify-end space-x-1.5 border-r border-gray-300"
              :class="!isModal ? 'pb-2' : ''"
            >
              <!-- 출고상태 -->
              <div class="pr-2">
                <ArticleStatusUI
                  :approve-code="approveTypeCd"
                  :is-cuesheet-matching="isMatchingCuesheet"
                />
              </div>
              <!-- 저장 -->
              <GSaveButton
                v-tooltip="'기사 저장'"
                icon="arrow-down-tray"
                :disabled="isLoading"
                :confirm-data="confirmData"
                :loading="isSaveLoading"
                text="저장"
                @click="onSaveArticle"
              />

              <!-- 맞춤법 -->
              <GButton
                v-tooltip="'맞춤법'"
                icon="spellcheck"
                :disabled="isLoading"
                @click="isShowSpellCheckModal = true"
              />
              <!-- 인쇄 -->
              <GButton
                v-tooltip="'인쇄'"
                icon="printer"
                :disabled="isLoading"
                @click="isShowPrintOptionModal = true"
              />

              <!-- 홈페이지 전송  -->
              <GConfirmButton
                v-if="!isShowSendHomePageButton"
                v-tooltip="t('MyRegionArticle.홈페이지_전송')"
                icon="cloud-arrow-up"
                :disabled="isDisabledSendHomePageButton"
                :title="title"
                :html="setConfirmHtml('homepage')"
                @on-confirm="onClickedSendHomepage"
              />

              <!-- 홈페이지 전송 취소 -->
              <GConfirmButton
                v-if="isShowSendHomePageButton"
                v-tooltip="t('MyRegionArticle.홈페이지_전송_취소')"
                icon="cloud"
                :disabled="isDisabledUnSendHomePageButton"
                :title="title"
                :html="setConfirmHtml('cancelHomepage')"
                @on-confirm="onUnSendHomePage"
              />

              <!-- 송고 -->
              <GConfirmButton
                v-if="currentRouteName === 'myArticle'"
                v-tooltip="'송고'"
                icon="document-arrow-up"
                :disabled="isLoading || isSaveLoading"
                :loading="isSaveLoading"
                :title="title"
                :html="setConfirmHtml('transfer')"
                text="송고"
                @on-confirm="isTransfer = true"
              />
              <!-- 출고 -->
              <GConfirmButton
                v-if="currentRouteName === 'coverDesk'"
                v-tooltip="'출고'"
                icon="chul-go"
                :disabled="isLoading || isSaveLoading"
                :loading="isSaveLoading"
                :title="title"
                :html="setConfirmHtml('approve')"
                text="출고"
                :show-confirm-button="setConfirmButton"
                confirm-button-text="출고(기사)"
                :show-deny-button="true"
                deny-button-text="출고(기사+자막)"
                deny-button-color="#22C55E"
                confirm-button-color="#dc3741"
                :input="isShowSendHomePageCheckBox"
                :input-placeholder="`홈페이지 전송`"
                :input-value="false"
                :return-input-value-on-deny="useValueOnDeny"
                @on-confirm="okConfirmHandler"
                @on-deny="deniedConfirmHandler"
              />
            </div>
          </div>
          <div class="flex">
            <!-- 참조기사 영역 -->
            <Reference
              :article-id="id"
              :is-modal="isModal"
              :route-name="currentRouteName"
            />

            <!-- 기사 작성 영역 -->
            <div class="flex border-r border-gray-300 bg-white">
              <div class="p-1.5 space-y-1">
                <!-- 버튼 영역 -->
                <div class="flex justify-between items-center">
                  <div class="flex items-center space-x-2">
                    <!-- 형식 -->
                    <ArticleTypeSelect
                      v-model="articleType"
                      :all-option="false"
                      width="w-48"
                    />
                    <!-- 자막완료 여부 -->
                    <GSwitch
                      v-tooltip="'자막완료 여부'"
                      v-model="isCompleteCap"
                    />
                  </div>
                  <div class="flex justify-end space-x-1.5">
                    <GIconButton
                      v-tooltip="'특수문자 입력'"
                      icon="special-key"
                      @click="onClickSpecialChar"
                    />
                    <GIconButton
                      v-tooltip="'앵커'"
                      icon="anchor"
                      @click="onInputMacro('anchor')"
                    />
                    <GIconButton
                      v-tooltip="'리포트'"
                      icon="camera"
                      @click="onInputMacro('reporter')"
                    />
                    <GIconButton
                      v-tooltip="'INT'"
                      icon="int"
                      @click="onInputMacro('int')"
                    />
                    <GIconButton
                      v-tooltip="'SYNC'"
                      icon="sync"
                      @click="onInputMacro('sync')"
                    />
                    <GIconButton
                      v-tooltip="'CG'"
                      icon="cg"
                      @click="onInputMacro('cg')"
                    />
                    <GIconButton
                      v-tooltip="'ST-UP'"
                      icon="stup"
                      @click="onInputMacro('st-up')"
                    />
                    <GIconButton
                      v-tooltip="'END'"
                      icon="end"
                      @click="onInputMacro('end')"
                    />
                    <GIconButton
                      v-if="type === articleTypeCode.CrossTalk.id"
                      v-tooltip="'추가'"
                      icon="plus-small"
                      @click="addEditor"
                    />
                    <GIconButton
                      v-if="type === articleTypeCode.CrossTalk.id"
                      v-tooltip="'삭제'"
                      icon="minus-small"
                      @click="removeEditor"
                    />
                  </div>
                </div>

                <!-- 제목 -->
                <div class="flex space-x-1 text-xs pb-1">
                  <GInput
                    v-model="title"
                    ref="titleRef"
                    id="article_title_input"
                    name="title"
                    :title="title"
                    placeholder="제목을 입력하세요."
                    :disabled="
                      approveTypeCd === articleApproveCode.articleApprov.id
                    "
                    text-size="text-[15px]"
                  />
                </div>

                <!-- 작성기 영역 -->
                <div class="overflow-y-auto h-[calc(100vh-238px)]">
                  <div v-if="!type" class="w-[704px]">
                    <PageLoader />
                  </div>
                  <ArticleEditor
                    ref="articleEditor"
                    v-if="type"
                    :type-id="type"
                    v-model:reporter-text-array="
                      articleEditorState.reporterTextArray
                    "
                    v-model:anchor-text-array="
                      articleEditorState.anchorTextArray
                    "
                    v-model:caption-array="
                      articleEditorState.reporterCaptionArray
                    "
                    :reporter-editor-read-only="
                      approveTypeCd === articleApproveCode.articleApprov.id
                    "
                    :anchor-editor-read-only="
                      approveTypeCd === articleApproveCode.articleApprov.id
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 메타 데이터 영역 -->
        <div
          v-show="setIsWideScreen || isShowMetaPanel"
          class="right-0 h-full bg-slate-200 rounded-br-lg"
          :class="setIsWideScreen ? 'w-[26rem] flex p-2' : 'w-[18rem] absolute'"
        >
          <div
            class="flex h-10 items-center justify-end px-2"
            :class="setIsWideScreen ? 'hidden' : ''"
          >
            <GIconButton
              v-tooltip="'닫기'"
              icon="chevron-right"
              @click="isShowMetaPanel = false"
            />
          </div>
          <GTabPanel :tabs="tabs" :selected-tab-id="selectedTabId">
            <TabPanel :unmount="false">
              <ArticleMetaData
                ref="articleMetaDataRef"
                v-model:article-type="articleType"
                v-model:article-reporter="reporter"
                v-model:article-program="program"
                :total-article-time="totalArticleTime"
                v-model:article-division="division"
                v-model:article-embargo="embargo"
                v-model:article-category="category"
                v-model:article-tag="tag"
                v-model:article-extra-time="extraTime"
                v-model:article-extra-time-type="extraTimeType"
                v-model:is-approve="isApprove"
                v-model:is-transfer="isTransfer"
                v-model:article-inputer="inputer"
                v-model:article-transfer-date="transferDate"
                v-model:article-approve-date="approveDate"
                v-model:article-updateDate="updateDate"
                v-model:article-approve="approveTypeCd"
                :article-channel-id="articleChannelId"
                :is-same-channel="isSameChannel"
                article-mode="edit"
                :height="setArticleMetaDataHeight"
                @selected-type="onSelectedType"
                @on-approve="onProcessApprove"
                @on-transfer="onProcessTransfer"
                @error-msg="isErrorMsg"
              />
            </TabPanel>
            <TabPanel :unmount="false">
              <ArticleAttachFile
                :file-list="files"
                :item-type="currentRouteName"
                :is-modal="isModal"
                :is-loading="isFileLoading"
                item-mode="edit"
                :height="setArticleAttachFileHeight"
                @on-upload-file="onUploadFile"
                @on-delete-file="onDeleteFile"
              />
            </TabPanel>
            <TabPanel :unmount="false">
              <ArticleLink
                :height="setArticleLinkHeight"
                :article-id="id"
                truncate-width="max-w-[17.5rem]"
              />
            </TabPanel>
            <TabPanel :unmount="false">
              <ArticleActions
                v-if="currentRouteName !== 'myArticle'"
                :article-id="id"
                item-type="edit"
                :height="setArticleLinkHeight"
              />
            </TabPanel>
          </GTabPanel>
        </div>
        <div
          class="w-full flex justify-end bg-gray-100"
          :class="setIsWideScreen ? 'hidden' : ''"
        >
          <div class="w-12 bg-white p-2 border-l border-gray-300 h-full">
            <GIconButton
              v-tooltip="'열기'"
              icon="chevron-left"
              @click="isShowMetaPanel = true"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 특수 문자 모달 -->
    <SpecialCharModal
      v-if="isShowSpecialCharModal"
      @selected-char="onSelectedChar"
      @close="isShowSpecialCharModal = false"
    />

    <!-- 프린트 옵션 모달 -->
    <ArticlePrintModal
      v-if="isShowPrintOptionModal"
      :print-data="printData"
      @close="isShowPrintOptionModal = false"
    />

    <!-- 맞춤법 검사 모달 -->
    <SpellCheckModal
      v-if="isShowSpellCheckModal"
      :editor-data="articleEditorState"
      :article-type="type"
      @close="isShowSpellCheckModal = false"
    />
  </div>

  <!-- MQ 강제 언락 메세지 경고모달 -->
  <AlertModal
    :is-open="isLockMessageModalOpen"
    :alert-title="alertTitle"
    :alert-content="alertContent"
    :alert-sub-content="alertSubContent"
    @close="closeLockModal"
  />
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RouterName } from '@/types/router';
import {
  GButton,
  GConfirmButton,
  GIconButton,
  GInput,
  GSaveButton,
  GSwitch,
  GTabPanel,
} from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { TabPanel } from '@headlessui/vue';
import {
  ArticleActions,
  ArticleLink,
  ArticleMetaData,
} from '@/components/article';
import ArticleAttachFile from '@/components/common/ArticleAttachFile.vue';
import { useStore } from 'vuex';
import Reference from '@/components/article/reference/Reference.vue';
import SpecialCharModal from '@/components/modal/article/SpecialCharModal';
import ArticleEditor from '@/components/article/ArticleEditor';
import {
  articleApproveCode,
  articleDivisionCode,
  articleTypeCode,
} from '@/codes';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useUserInfo } from '@/hooks/use-user-info';
import {
  calculateArticleTime,
  calculateSpeed,
  changeByte,
  isChangedDataOfEdit,
  makeDBCaptionForm,
  setMacro,
  setTagNames,
} from '@/utils/article-helper';
import { setEditorValues } from '@/utils/editor-helper';
import {
  defaultDate,
  defaultDateTime,
  displayDateTimeHm,
  secondToMs,
} from '@/utils/format';
import PageLoader from '@/components/common/PageLoader.vue';
import { ArticleStatusUI } from '@/components/article/commonUI';
import { cloneDeep } from 'lodash';
import { useForm } from 'vee-validate';
import yup from '@/utils/yup';
import ArticlePrintModal from '@/components/modal/article/ArticlePrintModal.vue';
import { useSweetAlert } from '@/hooks/use-sweet-alert';
import { parse } from '@/utils/videotime.js';
import { RabbitMQ } from '@/enums';
import SpellCheckModal from '@/components/modal/article/SpellCheckModal.vue';
import AlertModal from '@/components/modal/alert/AlertModal.vue';
import { ArticleTypeSelect } from '@/components/select';
import { initCaptions } from '@/utils/print';
import { useWindowSize } from '@/hooks/use-window-size';
import { AppAuthorityEnum } from '@/enums/app-authority.ts';
import { insertTextBetWeen } from '@/utils/str';

export default defineComponent({
  name: RouterName.EditArticle,
  components: {
    ArticleEditor,
    GButton,
    GSaveButton,
    GIconButton,
    GSwitch,
    GInput,
    GTabPanel,
    TabPanel,
    ArticleMetaData,
    ArticleAttachFile,
    ArticleLink,
    ArticleActions,
    Reference,
    SpecialCharModal,
    PageLoader,
    ArticleStatusUI,
    ArticlePrintModal,
    GConfirmButton,
    SpellCheckModal,
    AlertModal,
    ArticleTypeSelect,
  },
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['original-data', 'save-success', 'success-transfer'],
  setup(props, { emit }) {
    const { windowWidth } = useWindowSize();
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const marsLApi = useMarsLApi();
    const { companyId, userId, userMngId, can } = useUserInfo();
    const { swalError } = useSweetAlert();

    const articleEditor = ref();

    const alertTitle = ref(``);
    const alertContent = ref(``);
    const alertSubContent = ref(``);

    const isLoading = ref(false);
    const isSameChannel = ref(false);
    const isSaveLoading = ref(false);
    const isFileLoading = ref(false);
    const isLockMessageModalOpen = ref(false);
    const article = ref(null);
    const articleEditorState = ref({
      anchorTextArray: [''],
      anchorCaptionArray: [[]], // c/t형태: [[{}],[{}],[{}]...]
      reporterTextArray: [''],
      reporterCaptionArray: [[]],
    });
    const title = ref(); // 제목
    const type = ref(null); // 초기값은 null로 설정해야 한다.
    const articleType = ref(); // 형식
    const reporter = ref(); // 기자
    const reporterId = ref(); // 기자 ID
    const program = ref(); // 프로그램
    const division = ref(); // 그분
    const category = ref(); // 카테고리
    const embargo = ref(); // 엠바고
    const tag = ref([]); // 태그
    const extraTime = ref('00:00');
    const extraTimeType = ref('+');
    const inputer = ref(null); // 작성자
    const transferDate = ref(null); // 송고일시
    const approveDate = ref(null); // 출고일시
    const updateDate = ref(null); // 업데이트 일시
    const approveTypeCd = ref(null); // 출고상태

    const isTransfer = ref(false); // 송고 버튼 체크
    const transferYn = ref('N'); // 송고여부

    const isApprove = ref(false); // 출고 버튼 체크
    const toBeApproveCd = ref(null); // 변경될 출고 상태

    const confirmData = ref(null);
    const isMatchingCuesheet = ref(false);

    const isShowPrintOptionModal = ref(false);
    const isShowConfirmModal = ref(false);
    const originData = ref(null);
    const isErrorExtraTime = ref(false);
    const isShowSpellCheckModal = ref(false);
    const titleRef = ref();
    const cursorPostion = ref(0);

    const printData = ref([]);
    let speed = null;

    let selectedTabId = ref(0);
    const isCompleteCap = ref(false);
    const files = ref([]);
    const isShowReferenceModal = ref(false);
    const isShowSpecialCharModal = ref(false);
    const totalArticleTime = ref(0);

    const isShowMetaPanel = ref(false);
    const articleMetaDataRef = ref(null);
    const articleChannelId = ref(0);
    const isExport = ref(false);
    const isSendHomepageCheckBox = ref();

    // dynamic tabs
    const tabs = computed(() => {
      let tabList = [
        { id: 1, name: '메타데이터' },
        { id: 2, name: '첨부파일' },
        { id: 3, name: '링크' },
      ];

      // 내기사에서는 이력이 보이지 않도록록
      if (
        route.name !== RouterName.MyArticle &&
        route.name !== RouterName.CreateMyArticle
      ) {
        tabList.push({
          id: tabList.length + 1,
          name: t('PreviewArticle.이력'),
        });
      }

      return tabList;
    });

    // 첨부파일 탭에서 가져오려는 첨부파일의 api를 사용하기 위함
    const currentRouteName = computed(() => {
      let name = '';

      if (
        route?.name === RouterName.MyArticle ||
        route?.name === RouterName.CreateMyArticle
      ) {
        name = 'myArticle';
      } else if (route?.name === RouterName.EditArticle) {
        name = 'coverDesk';
      }

      return name;
    });

    // validation 적용
    const { validate } = useForm({
      initialValues: {
        title: null,
      },
      validationSchema: yup.object().shape({
        title: yup.string().required(),
      }),
    });

    // 메타데이터 탭에서 extraTime이 제대로 입력되어있지 않은 경우 false를 넘겨받는다.
    const isErrorMsg = isCorrectExtraTime => {
      isErrorExtraTime.value = isCorrectExtraTime;
    };

    // 선택한 특수문자 가져오기
    const onSelectedChar = char => {
      if (specialCharTarget === 'editor') {
        articleEditor.value.insertTextAtCursor(char);
      } else if (specialCharTarget === 'title') {
        if (title.value === undefined) {
          title.value = char;
        } else {
          title.value = insertTextBetWeen(
            title.value,
            char,
            cursorPostion.value,
          );
          cursorPostion.value += char.length;
        }
      }
    };

    // 선택한 매크로 글자 넣기
    const onInputMacro = type => {
      // 기사만 출고된 경우 작성기 영역에 글자 입력되지 않도록
      if (approveTypeCd.value === articleApproveCode.articleApprov.id) {
        return;
      }

      const char = setMacro(type);
      articleEditor.value.insertTextAtCursor(char);
    };

    // 기사 락 해제
    const unLockArticle = async articleId => {
      try {
        await marsLApi.coverDesk.basic.unLock(parseInt(articleId));
      } catch (error) {
        console.error('error', error);
      }
    };

    // 기사 저장
    const onSaveArticle = async () => {
      // validation 체크(제목, 기자, 엑스트라 타임 유효성 검사)
      const valid = await articleValidate();

      if (!valid) {
        window.Notify.error({ message: '필수 입력 항목을 확인해주세요.' });
        return;
      }

      // 기사작성기 자막을 형태를 DB형태로 변경
      const { articleAnchorCaps, articleCaps } = makeDBCaptionForm(
        articleType.value,
        articleEditorState.value,
      );

      let response = null;
      let tagIdList = [];
      isSaveLoading.value = true;

      try {
        if (tag.value.length) {
          const params = {
            tagNameList: setTagNames(tag.value),
          };
          const response = await marsLApi.tag.basic.create(params);
          if (response.success) {
            tagIdList = response.data.tagIdList;
          }
        }
        // 내기사, 취재제스크 공통 params
        let params = {
          artclTypCd: articleType.value?.id, // 형식
          artclTitl: title.value,
          rptrId: reporter.value?.id ? reporter.value?.id : reporterId.value, // 타지역사에서 복사한 기사의 경우 id를 가지고 있지 않아서 조회시 rptrId값을 보내준다.
          brdcPgmId: program.value?.id || null,
          artclDivCd: division.value?.id, // 일반or엠바고or예정
          artclDivDtm: defaultDateTime(embargo.value),
          artclCateId: category.value?.id || null,
          // artclClass: '', // 기사 종류 코드
          // 앵커 내용
          ancMentCtt: `${JSON.stringify(
            articleEditorState.value.anchorTextArray,
          )}`,
          // 앵커 시간
          ancMentCttTime: calculateSpeed(
            speed,
            changeByte(articleEditorState.value.anchorTextArray),
          ),
          // 리포트 내용
          artclCtt: `${JSON.stringify(
            articleEditorState.value.reporterTextArray,
          )}`,
          // 리포트 시간
          artclCttTime: calculateSpeed(
            speed,
            changeByte(articleEditorState.value.reporterTextArray),
          ),
          artclExtTime:
            extraTimeType.value === '+'
              ? parse(extraTime.value)
              : -parse(extraTime.value),
          capFnshYn: isCompleteCap.value ? 'Y' : 'N',
          // 태그
          tagIdList,
        };

        if (currentRouteName.value === 'myArticle') {
          params = {
            ...params,
            myArticleAnchorCapCreateDtoList: articleAnchorCaps, // 앵커 자막
            myArticleCapCreateDtoList: articleCaps, // 리포트 자막
          };

          response = await marsLApi.myArticle.basic.update(
            parseInt(props.id),
            params,
          );
        } else if (currentRouteName.value === 'coverDesk') {
          params = {
            ...params,
            articleAnchorCaps, // 앵커 자막
            articleCaps, // 리포트 자막
            chDivId: companyId,
            id: parseInt(props.id),
          };

          response = await marsLApi.coverDesk.basic.update(
            parseInt(props.id),
            params,
          );
        }

        if (response.success) {
          window.Notify.success({
            message: '[기사 저장] 완료되었습니다.',
          });
        }

        // 수정된 데이터를 원본 데이터로 변경
        const cloneArticleEditor = cloneDeep(articleEditorState.value);
        originData.value = {
          title: title.value,
          ...cloneArticleEditor,
          type: articleType.value?.id,
        };
        emit('original-data', originData.value);
        printData.value = [
          initCaptions({
            ...response.data,
            artclDivCd: division?.id,
            artclDivDtm: defaultDateTime(embargo),
            ...cloneArticleEditor,
          }),
        ];

        // 기사 수정 모달에서 기사를 저장하면 모달 닫기 처리
        if (props.isModal) {
          emit('save-success');
          return response;
        }

        // 현재 활성화된 기사 수정 탭을 담아놓는다.
        const activeTab = store.getters['tab/activeTab'];

        // 탭의 변경사항 초기화
        activeTab.canClose = true;

        // 탭 닫기로 기사를 저장한 경우 탭이 닫힌다.
        if (activeTab.clickedTabClose) {
          // 락 해제
          unLockArticle(props.id);

          // 기사 수정 탭 없앤다.
          store.dispatch('tab/DELETE_TAB', activeTab);
        }

        if (currentRouteName.value === 'coverDesk') {
          store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
            reason: 'save',
          });
          store.dispatch('refresh/FORCE_REFRESH'); // 이력 새로고침
          store.dispatch('tab/CHANGE_TAB_NAME', {
            tab: activeTab,
            title: title.value,
          });
        }
      } catch (error) {
        swalError({
          showCancelButton: false,
          title: '<div>기사를 저장할 수 없습니다.</div>',
          html: `<div>${error?.response?.data?.message}</div>`,
        });
      } finally {
        isSaveLoading.value = false;
      }
      // 성공, 실패 상관없이 실행
      store.commit('submit/RESET_STATE');
      // isSaveLoading.value = false;
      return response;
    };

    // C/T 선택 여부에 따른 +/-버튼 토글
    const onSelectedType = value => {
      type.value = value;
    };

    // emit files 가져오기
    const onGetFiles = getFiles => {
      files.value = getFiles;
    };

    const shotKeyEvent = event => {
      switch (event.srcKey) {
        case 'macSave':
        case 'winSave': {
          onSaveArticle();
          break;
        }
        case 'macMacro1':
        case 'winMacro1': {
          onInputMacro('anchor');
          break;
        }
        case 'macMacro2':
        case 'winMacro2': {
          onInputMacro('reporter');
          break;
        }
        case 'macMacro3':
        case 'winMacro3': {
          onInputMacro('int');
          break;
        }
        case 'macMacro4':
        case 'winMacro4': {
          onInputMacro('sync');
          break;
        }
        case 'macMacro5':
        case 'winMacro5': {
          onInputMacro('cg');
          break;
        }
        case 'macMacro6':
        case 'winMacro6': {
          onInputMacro('st-up');
          break;
        }
        case 'macMacro7':
        case 'winMacro7': {
          onInputMacro('end');
          break;
        }
      }
    };

    // 기사 구분에 따른 날짜 포멧
    const setEmbargoDate = (articleDiv, articleDivDate) => {
      let date = null;

      if (articleDiv === articleDivisionCode.embargo.id) {
        date = defaultDateTime(articleDivDate);
      } else {
        date = defaultDate(articleDivDate);
      }

      return date;
    };

    // 출고 버튼 클릭
    const onClickedApprove = () => {
      isShowConfirmModal.value = true;
      confirmData.value = {
        confirmType: 'approve',
        title: title.value,
        confirmButtonColor: '#dc3741',
        denyButtonColor: '#2F7097',
        showConfirmButton:
          approveTypeCd.value === articleApproveCode.articleApprov.id
            ? false
            : true,
        showDenyButton: true,
      };
    };

    // 출고 상태 변경 API 호출
    const callUpdateApprove = async apprvTypCd => {
      try {
        return await marsLApi.coverDesk.basic.updateApprove(
          parseInt(props.id),
          {
            apprvTypCd,
          },
        );
      } catch (error) {
        isShowConfirmModal.value = true;
        const msgType =
          apprvTypCd === articleApproveCode.noneApprove.id
            ? '출고 취소'
            : '출고';

        confirmData.value = {
          confirmType: 'confirm',
          message: `<div class="space-y-2"><div>기사를 ${msgType}할 수 없습니다.</div><div>${error?.response?.data?.message}</div></div>`,
          showCancelButton: false,
          btn: 'edit',
        };
        isShowConfirmModal.value = false;
      }
    };

    // 기사 출고
    const onApproveArticle = async () => {
      let response = null;
      try {
        response = await callUpdateApprove(articleApproveCode.articleApprov.id);

        if (response?.success) {
          window.Notify.success({
            message: `"${title.value}" 이(가) 기사 출고되었습니다.`,
          });

          // 홈페이지 전송
          if (isSendHomepageCheckBox.value === 1) {
            await onSendHomePage({ isRefreshArticles: false });
          }
        }
      } catch (error) {
        console.error('error', error);
      }

      return response;
    };

    // 기사 + 자막 출고
    const onApproveArticleCaption = async () => {
      let response = null;
      try {
        response = await callUpdateApprove(
          articleApproveCode.articleCaptionApprove.id,
        );

        if (response?.success) {
          window.Notify.success({
            message: `"${title.value}" 이(가) 기사 + 자막 출고되었습니다.`,
          });
          // 홈페이지 전송
          if (isSendHomepageCheckBox.value === 1) {
            await onSendHomePage({ isRefreshArticles: false });
          }
        }
      } catch (error) {
        console.error('error', error);
      }

      return response;
    };

    // 확인 버튼 클릭
    const okConfirmHandler = result => {
      isSendHomepageCheckBox.value = result;
      isApprove.value = true;
      toBeApproveCd.value = articleApproveCode.articleApprov.id;
    };

    // 거절 버튼 클릭
    const deniedConfirmHandler = result => {
      isSendHomepageCheckBox.value = result;
      isApprove.value = true;
      toBeApproveCd.value = articleApproveCode.articleCaptionApprove.id;
    };

    // 기사 출고 (저장->출고->뷰모드)
    const onProcessApprove = async valid => {
      // 필수 값 확인이 false인 경우, 탭을 메타데이터로 변경
      if (!valid) {
        selectedTabId.value = tabs.value[0].id;
        return;
      }

      try {
        // 저장
        let saveResponse = await onSaveArticle(valid);

        if (saveResponse?.success) {
          let approveResponse = null;
          // 출고
          if (toBeApproveCd.value === articleApproveCode.articleApprov.id) {
            approveResponse = await onApproveArticle(); // 기사 출고
          } else {
            approveResponse = await onApproveArticleCaption(); // 기사 + 자막 출고
          }

          if (approveResponse?.success) {
            // 현재 활성화된 기사 수정 탭을 담아놓는다.
            const activeTab = cloneDeep(store.getters['tab/activeTab']);

            // 현재 수정 기사의 탭을 닫고 기사 목록 탭으로 이동
            await store.dispatch('tab/DELETE_TAB', activeTab);
            await router.replace({ name: RouterName.MyRegionArticle });

            // 기사 수정 탭을 보기 탭으로 변경
            // store.dispatch('tab/CHANGE_TAB_MODE', {
            //   to: 'edit',
            //   mode: 'view',
            //   currentTab: activeTab,
            //   title: title.value,
            //   articleId: props.id,
            //   target: 'my-region',
            // });
          }
        }
      } catch (error) {
        console.error('error', error);
      }
    };

    const setConfirmHtml = type => {
      if (type === 'transfer') {
        return transferYn.value === 'Y'
          ? '<div>은(는) 이미 송고되었습니다. 재 송고 하시겠습니까?</div>'
          : '<div>(을)를 송고하시겠습니까?</div>';
      } else if (type === 'approve') {
        return '<div>기사를 출고하시겠습니까?</div>';
      }
    };

    const setConfirmButton = computed(() => {
      return approveTypeCd.value === articleApproveCode.articleApprov.id
        ? false
        : true;
    });

    // 기사 송고
    const onSendedArticle = async () => {
      try {
        const response = await marsLApi.myArticle.transfer.move(
          parseInt(props.id),
        );

        if (response.success) {
          window.Notify.success({
            message: '[기사 송고] 완료되었습니다.',
          });
          // initData();
          store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
            reason: 'transfer',
          });
          emit('success-transfer');
        }
      } catch (error) {
        swalError({
          showCancelButton: false,
          title: '<div>기사를 송고할 수 없습니다.</div>',
          html: `<div>${error?.response?.data?.message}</div>`,
        });
      }
    };

    // 기사 송고 (저장->송고)
    const onProcessTransfer = async valid => {
      // 필수 값 확인이 false인 경우, 탭을 메타데이터로 변경
      if (!valid) {
        selectedTabId.value = tabs.value[0].id;
        return;
      }

      // 기사 저장
      let saveResponse = await onSaveArticle();
      if (saveResponse?.success) {
        // 기사 송고
        await onSendedArticle();
      }
    };

    // 제목, 에디터 내용, 타입 변경 감지 및 기사 길이 계산
    watch(
      () => [title.value, articleEditorState.value, extraTime.value],
      ([newTitle, newEditorState]) => {
        // 기사 길이 계산 (메타데이터 탭에 표시)
        totalArticleTime.value = secondToMs(
          calculateArticleTime(
            newEditorState.anchorTextArray,
            newEditorState.reporterTextArray,
            speed,
          ),
        );

        // 모달에서 기사를 작성하는게 아닌 경우, 탭 닫기 버튼을 누르면 변경사항 확인
        if (!props.isModal) {
          const activeTab = store.getters['tab/activeTab'];

          const changeData = {
            title: newTitle,
            anchorCaptionArray: newEditorState.anchorCaptionArray,
            reporterCaptionArray: newEditorState.reporterCaptionArray,
            anchorTextArray: newEditorState.anchorTextArray,
            reporterTextArray: newEditorState.reporterTextArray,
            type: type.value,
          };
          const isChanged = isChangedDataOfEdit(originData.value, changeData);
          if (isChanged) {
            // 변경된 내용이 있다면 탭의 canClose 값 변경
            activeTab.canClose = false;
          } else {
            activeTab.canClose = true;
          }
        }
      },
      {
        deep: true,
      },
    );

    // 'title', 'editor'
    let activeInputElement = null;
    // 'title', 'editor'
    let specialCharTarget = null;

    const isArticleEditorElement = el => {
      return (
        (el.tagName === 'DIV' && el['spellcheck'] !== undefined) ||
        (el.tagName === 'TEXTAREA' && el.classList.contains('article-caption'))
      );
    };

    const isArticleTitleInputElement = el => {
      return el.tagName === 'INPUT' && el['id'] !== 'article_title_input';
    };

    const onClickSpecialChar = () => {
      specialCharTarget = null;
      if (activeInputElement) {
        specialCharTarget = activeInputElement;
        // 특수문자입력버튼 클릭시 타이틀 커서 위치 저장
        if (specialCharTarget === 'title') {
          cursorPostion.value = titleRef.value.onselectionStart();
        }
      }
      isShowSpecialCharModal.value = true;
    };

    const onDocumentMouseDown = () => {
      // 현재 포커스를 가지고 있는 요소
      const activeEl = document.activeElement;
      if (isArticleEditorElement(activeEl)) {
        activeInputElement = 'editor';
      } else if (isArticleTitleInputElement(activeEl)) {
        activeInputElement = 'title';
      } else {
        activeInputElement = null;
      }
    };

    // 등록된 첨부파일 가져오기
    const getFiles = async () => {
      isFileLoading.value = true;
      let articleFileListResponse = null;

      // 내 기사
      if (currentRouteName.value === 'myArticle') {
        articleFileListResponse = await marsLApi.myArticle.file.findAll(
          parseInt(props.id),
        );
      } else if (currentRouteName.value === 'coverDesk') {
        articleFileListResponse = await marsLApi.coverDesk.file.findAll(
          parseInt(props.id),
        );
      }

      if (articleFileListResponse.success) {
        files.value = articleFileListResponse.data.map(file => {
          return {
            ...file,
            name: file.fileName,
          };
        });
      }
      isFileLoading.value = false;
    };

    // 서버에 파일 업로드 및 기사에 파일 매칭
    const onUploadFile = async fileData => {
      const { list, fileDivCode } = fileData;

      try {
        isFileLoading.value = true;
        let successUploadCount = 0;

        for await (let file of list) {
          const params = {
            fileName: file.name,
            fileDivisionCode: fileDivCode,
            file,
          };

          const uploadResponse = await marsLApi.file.basic.upload(params);

          if (uploadResponse.success) {
            const params = {
              fileId: uploadResponse.data.id,
            };

            // 내기사일 경우
            if (currentRouteName.value === 'myArticle') {
              await marsLApi.myArticle.file.create(props.id, params);
            }
            // 취재데스크일 경우
            else if (currentRouteName.value === 'coverDesk') {
              await marsLApi.coverDesk.file.create(props.id, params);
            }

            successUploadCount = successUploadCount + 1;

            if (successUploadCount === list.length) {
              window.Notify.success({
                message: '[파일 업로드] 완료되었습니다.',
              });

              getFiles();

              if (currentRouteName.value === 'coverDesk') {
                store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
                  reason: 'uploadFile',
                });
              }
            }
          }
        }
      } catch (error) {
        console.error('error', error);
      }

      isFileLoading.value = false;
    };

    // 등록된 첨부파일 삭제
    const onDeleteFile = async file => {
      isFileLoading.value = true;
      let response = null;

      try {
        // 내기사일 경우
        if (currentRouteName.value === 'myArticle') {
          response = await marsLApi.myArticle.file.delete(props.id, file.id);
        }
        // 취재데스크일 경우
        else if (currentRouteName.value === 'coverDesk') {
          response = await marsLApi.coverDesk.file.delete(props.id, file.id);
        }

        if (response.success) {
          window.Notify.success({
            message: '[파일 삭제] 완료되었습니다.',
          });

          const delIdx = files.value.findIndex(item => item.name === file.name);
          files.value.splice(delIdx, 1);

          getFiles();

          if (currentRouteName.value === 'coverDesk') {
            store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
              reason: 'deleteFile',
            });
          }
        }
      } catch (error) {
        console.error('error', error);
      }
      isFileLoading.value = false;
    };

    // 초기화
    const initData = async () => {
      try {
        isLoading.value = true;
        let response = null;
        let anchorCapList = []; // 내기사, 취재데스크의 response CapList key값이 다름
        let articleCapList = [];

        // 내 기사
        if (currentRouteName.value === 'myArticle') {
          response = await marsLApi.myArticle.basic.findOne(parseInt(props.id));
          anchorCapList = response.myArticleAnchorCapList;
          articleCapList = response.myArticleCapList;
          tag.value = response.myArticleTagList?.map(tag => {
            return {
              ...tag.tag,
              tagName: tag.tag.tagNm,
            };
          });
          // 송고일시
          transferDate.value = response?.artclTrnsfDtm
            ? displayDateTimeHm(response?.artclTrnsfDtm)
            : '';

          isSameChannel.value = true;
        }
        // 취재데스크
        else if (currentRouteName.value === 'coverDesk') {
          response = await marsLApi.coverDesk.basic.findOne(parseInt(props.id));
          anchorCapList = response.articleAnchorCaps;
          articleCapList = response.articleCaps;
          tag.value = response.articleTags?.map(tag => {
            return {
              ...tag.tag,
              tagName: tag.tag.tagNm,
            };
          });
          // 송고일시
          transferDate.value = displayDateTimeHm(response.inputDtm);
          isMatchingCuesheet.value = response?.cueMatchCnt > 0 ? true : false;

          isSameChannel.value = response.isSameChannel || false;

          isExport.value = response.exportYn === 'Y';
        }

        // 기사에 등록된 첨부파일 가져오기
        getFiles();

        // 에디터에 값 대입
        articleEditorState.value = setEditorValues({
          artclTypCd: response.artclTypCd,
          anchorCapList,
          articleCapList,
          ancMentCtt: response.ancMentCtt || JSON.stringify(['\n']),
          artclCtt: response.artclCtt || JSON.stringify(['\n']),
        });

        type.value = null;
        type.value = response.artclTypCd;
        title.value = response.artclTitl; // 제목
        articleType.value = response.artclTypCd; // 형식

        reporterId.value = response.rptrId;
        // 참조기사의 경우 기자가 없으므로 로그인 사용자를 초기값으로 설정
        reporter.value = !response.rptrId
          ? userMngId
          : response.rptrId && isSameChannel.value
          ? response.rptrId
          : `${response.orgRptrNm}(${response.orgChNm})`;
        program.value = response.brdcPgmId; // 프로그램
        division.value = response.artclDivCd; // 구분
        // 엠바고
        embargo.value = setEmbargoDate(
          response.artclDivCd,
          response.artclDivDtm,
        );
        extraTime.value = secondToMs(Math.abs(response.artclExtTime));
        extraTimeType.value = response.artclExtTime >= 0 ? '+' : '-';

        category.value = response.artclCateId; // 카테고리
        isCompleteCap.value = response.capFnshYn === 'Y' ? true : false;
        inputer.value = isSameChannel.value
          ? response.inputrNm
          : `${response.orgInputrNm}(${response.orgChNm})`; // 작성자
        updateDate.value = displayDateTimeHm(response.updtDtm); // 업데이트 일시
        approveTypeCd.value = response.apprvTypCd; // 출고상태
        transferYn.value = response?.artclTrnsfYn; // 송고여부
        articleChannelId.value = response.chDivId;

        // 데이터 원본
        const cloneArticleEditor = cloneDeep(articleEditorState.value);
        originData.value = {
          title: response.artclTitl,
          ...cloneArticleEditor,
          type: response.artclTypCd,
        };
        emit('original-data', originData.value);
        printData.value = [
          initCaptions({ ...response, ...cloneArticleEditor }),
        ];
      } catch (error) {
        console.error('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    /**
     * 기사 강제 잠금 해제 경고 메시지
     */
    const forceUnLockNotifyEvent = data => {
      if (userId === data.userId) {
        return;
      }
      if (parseInt(props.id) !== data.articleId && !data.isForce) {
        return;
      }
      // modal open
      isLockMessageModalOpen.value = true;
      alertTitle.value = `기사가 잠금 해제되었습니다.`;
      alertContent.value = `작성된 기사를 메모장 등에 백업 후 잠금 해제자와 기사변경 내용을 확인해주세요.`;
      alertSubContent.value = `잠금 해제자 : ${data.userName}(${data.userId})
      잠금 해제일시 : ${defaultDateTime(data.lockDateTime)}`;
    };

    const closeLockModal = () => {
      isLockMessageModalOpen.value = false;
    };

    const addEditor = () => {
      articleEditor.value.addEditor();
    };

    const removeEditor = () => {
      articleEditor.value.removeEditor();
    };

    /**
     * 기사 저장시 유효성 검사
     */
    const articleValidate = async () => {
      let dataValidate = false;
      // 제목 필수값 체크
      dataValidate =
        (await validate()).valid &&
        isErrorExtraTime.value &&
        (reporter.value ? true : false);

      return dataValidate;
    };

    /**
     * 윈도우 창이 1280px이상이면 true
     */
    const setIsWideScreen = computed(() => {
      return windowWidth.value >= 1280;
    });

    /**
     * ArticleMetaData 컴포넌트 height
     */
    const setArticleMetaDataHeight = computed(() => {
      let height = '';
      if (windowWidth.value > 1280) {
        height = props.isModal
          ? 'h-[calc(100vh-171px)]'
          : 'h-[calc(100vh-165px)]';
      } else {
        height = props.isModal
          ? 'h-[calc(100vh-197px)]'
          : 'h-[calc(100vh-190px)]';
      }
      return height;
    });

    /**
     * ArticleAttachFile 컴포넌트 height
     */
    const setArticleAttachFileHeight = computed(() => {
      let height = '';
      if (windowWidth.value > 1280) {
        height = props.isModal
          ? 'h-[calc(100vh-171px)]'
          : 'h-[calc(100vh-168px)]';
      } else {
        height = props.isModal
          ? 'h-[calc(100vh-197px)]'
          : 'h-[calc(100vh-190px)]';
      }
      return height;
    });

    /**
     * ArticleLink 컴포넌트 height
     */
    const setArticleLinkHeight = computed(() => {
      let height = '';
      if (windowWidth.value > 1280) {
        height = props.isModal
          ? 'h-[calc(100vh-171px)]'
          : 'h-[calc(100vh-168px)]';
      } else {
        height = props.isModal
          ? 'h-[calc(100vh-197px)]'
          : 'h-[calc(100vh-190px)]';
      }
      return height;
    });

    /**
     * ArticleActions 컴포넌트 height
     */
    const setArticleActionsHeight = computed(() => {
      let height = '';
      if (windowWidth.value > 1280) {
        height = props.isModal
          ? 'h-[calc(100vh-213px)]'
          : 'h-[calc(100vh-168px)]';
      } else {
        height = '';
      }
      return height;
    });

    /**
     * sweetAlert 체크박스 사용여부 - 홈페이지 전송 되었으면 사용하지 않음
     * @type {ComputedRef<string>}
     */
    const isShowSendHomePageCheckBox = computed(() => {
      return !can(AppAuthorityEnum?.ARTICLE_HOMEPAGE_SEND.code) ||
        isExport.value
        ? ''
        : `checkbox`;
    });

    /**
     * sweetAlert input 타입 사용하지 않을시 true로 값을 주면 에러가 나기때문에
     * 홈페이지 전송이 되었을 경우에 input타입을 사용하지 않기때문에 return-input-value-on-deny 값도 false로 변경해준다
     * @type {ComputedRef<boolean>}
     */
    const useValueOnDeny = computed(() => {
      return (
        can(AppAuthorityEnum?.ARTICLE_HOMEPAGE_SEND.code) && !isExport.value
      );
    });

    const isShowSendHomePageButton = computed(() => {
      return isExport.value;
    });

    const isDisabledSendHomePageButton = computed(() => {
      let disabled = false;
      disabled =
        !can(AppAuthorityEnum?.ARTICLE_HOMEPAGE_SEND.code) ||
        approveTypeCd.value === articleApproveCode.noneApprove.id;
      return disabled;
    });

    const isDisabledUnSendHomePageButton = computed(() => {
      let disabled = false;
      disabled = !can(AppAuthorityEnum?.ARTICLE_HOMEPAGE_SEND.code);

      return disabled;
    });

    /**
     * 홈페이지 전송 버튼 클릭
     */
    const onClickedSendHomepage = async () => {
      const response = await onSaveArticle();
      if (response.success) {
        await onSendHomePage();
      }
    };

    /**
     * 홈페이지 전송
     */
    const onSendHomePage = async ({ isRefreshArticles = true } = {}) => {
      try {
        const response = await marsLApi.coverDesk.basic.sendHomePage(
          parseInt(props.id),
        );
        if (response.success) {
          if (isRefreshArticles) {
            await initData();
          }
        }
      } catch (error) {
        console.error('send homepage error', error);
      }
    };

    /**
     * 홈페이지 전송 취소
     */
    const onUnSendHomePage = async ({ isRefreshArticles = true } = {}) => {
      try {
        const response = await marsLApi.coverDesk.basic.unSendHomePage(
          parseInt(props.id),
        );
        if (response.success) {
          if (isRefreshArticles) {
            await initData();
          }
        }
      } catch (error) {
        console.error('unsend homepage error', error);
      }
    };

    onMounted(async () => {
      initData();

      await store.dispatch('systemInfo/FIND_MY_SYSTEM_INFO');
      speed = store.getters['systemInfo/readSpeed'];

      /**
       * 기사 강제 잠금 해제 mq
       */
      window.EventBus.on(
        RabbitMQ.getMqEventBusEmitName(
          RabbitMQ.Exchanges.article,
          RabbitMQ.MqEventBusEmitEnum.UPDATE_FORCE_UNLOCK_ARTICLE,
        ),
        async eventMessage => {
          forceUnLockNotifyEvent(eventMessage.data);
        },
      );

      // 이벤트 중복 등록 방지를 위한 코드
      window.EventBus.off(`edit-article-from-close-tab-${props.id}`);
      window.EventBus.on(`edit-article-from-close-tab-${props.id}`, () => {
        onSaveArticle();
      });

      document.addEventListener('mousedown', onDocumentMouseDown);
    });

    onUnmounted(() => {
      document.removeEventListener('mousedown', onDocumentMouseDown);
    });

    return {
      t,
      route,
      RouterName,
      isLoading,
      isSaveLoading,
      article,
      articleTypeCode,
      articleEditorState,
      articleEditor,
      tabs,
      files,
      selectedTabId,
      isShowReferenceModal,
      isShowSpecialCharModal,
      type,
      totalArticleTime,
      isCompleteCap,
      onSelectedType,
      onSelectedChar,
      onGetFiles,
      title,
      articleType,
      reporter,
      program,
      division,
      category,
      embargo,
      tag,
      extraTime,
      isApprove,
      isTransfer,
      inputer,
      approveDate,
      transferDate,
      updateDate,
      isMatchingCuesheet,
      approveTypeCd,
      articleApproveCode,
      onSaveArticle,
      onInputMacro,
      confirmData,
      onClickedApprove,
      onApproveArticle,
      onProcessApprove,
      onProcessTransfer,
      okConfirmHandler,
      deniedConfirmHandler,
      isShowPrintOptionModal,
      isShowConfirmModal,
      setConfirmHtml,
      setConfirmButton,
      isErrorMsg,
      isShowSpellCheckModal,
      isLockMessageModalOpen,
      alertTitle,
      alertContent,
      alertSubContent,
      closeLockModal,
      currentRouteName,
      printData,
      shotKeyEvent,
      addEditor,
      removeEditor,
      isShowMetaPanel,
      onClickSpecialChar,
      articleMetaDataRef,
      articleChannelId,
      extraTimeType,
      isFileLoading,
      onUploadFile,
      onDeleteFile,
      isSameChannel,
      setIsWideScreen,
      setArticleMetaDataHeight,
      setArticleAttachFileHeight,
      setArticleLinkHeight,
      setArticleActionsHeight,
      isShowSendHomePageCheckBox,
      useValueOnDeny,
      isShowSendHomePageButton,
      isDisabledSendHomePageButton,
      onSendHomePage,
      onUnSendHomePage,
      onClickedSendHomepage,
      isDisabledUnSendHomePageButton,
      titleRef,
    };
  },
});
</script>
